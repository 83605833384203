/** @jsx jsx */
import {jsx} from "theme-ui"
import React from "react";
import AnchorLink from "./anchor-link";
import PropTypes from "prop-types";

function isUnderDepthLimit(depth, maxDepth) {
    if (maxDepth === null) {
        // if no maxDepth is passed in, continue to render more items
        return true
    } else {
        return depth < maxDepth
    }
}

// depth and maxDepth are used to figure out how many bullets deep to render in the ToC sidebar, if no
// max depth is set via the tableOfContentsDepth field in the frontmatter, all headings will be rendered
function createItems(items, depth, maxDepth, activeItem) {
    return (
        items &&
        items.map((item, index) => {
                const isActive = (activeItem === "NONE") ? (index === 0 && depth === 1) : (item.url === activeItem);
                return (
                    <li key={'toc-' + (item.url || depth + `-` + index)}>
                        {item.url && (
                            <AnchorLink
                                offset={30}
                                className={'block py-2 px-2 text-xs border-l-4 ' +
                                'hover:bg-primary-200  hover:text-primary-900 hover:border-primary-400 ' +
                                (isActive ? 'border-primary-200 bg-primary-100 text-primary-900'
                                        : 'text-primary-800 border-primary-100'
                                )
                                }
                                href={item.url}
                            >
                                {item.title}
                            </AnchorLink>
                        )}
                        {item.items && isUnderDepthLimit(depth, maxDepth) && (
                            <ul className={'ml-4'}>
                                {createItems(item.items, depth + 1, maxDepth, activeItem)}
                            </ul>
                        )}
                    </li>
                )
            }
        )
    )
}


class TableOfContents extends React.Component {

    static propTypes = {
        maxDepth: PropTypes.number.isRequired,
        activeItemHash: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        items: PropTypes.array.isRequired
    };

    render() {
        return this.props.items ? (
            <nav>
                <h2 className={'font-semibold px-0 uppercase text-sm text-primary-800 mb-4'}>
                    {this.props.title}
                </h2>
                <ul>
                    {createItems(
                        this.props.items,
                        1,
                        this.props.maxDepth,
                        this.props.activeItemHash
                    )}
                </ul>
            </nav>
        ) : null
    }
}

export default TableOfContents