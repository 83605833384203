import React, {Component} from "react"
import TableOfContents from "./table-of-contents";
import PropTypes from "prop-types";

class TableOfContentsWithScrollSpy extends Component {

    static propTypes = {
        maxDepth: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        items: PropTypes.array
    };

    static defaultProps = {
        items: []
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            activeItemHash: `NONE`,
            itemTopOffsets: [],
        };

        this.calculateItemTopOffsets = this.calculateItemTopOffsets.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        this.calculateItemTopOffsets();
        window.addEventListener(`resize`, this.handleResize);
        window.addEventListener(`scroll`, this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener(`resize`, this.handleResize);
        window.removeEventListener(`scroll`, this.handleScroll);
    }

    calculateItemTopOffsets() {
        const itemIds = getFlatScrollTargets(this.props.items, 1, this.props.maxDepth);
        this.setState({
            itemTopOffsets: _getElementTopOffsetsById(itemIds),
        })
    }

    handleResize() {
        this.calculateItemTopOffsets();
        this.handleScroll();
    }

    handleScroll() {
        const {itemTopOffsets} = this.state;
        const item = itemTopOffsets.find((itemTopOffset, i) => {
            const nextItemTopOffset = itemTopOffsets[i + 1];

            return nextItemTopOffset
                ? window.scrollY >= itemTopOffset.offsetTop &&
                window.scrollY < nextItemTopOffset.offsetTop
                : window.scrollY >= itemTopOffset.offsetTop
        });

        this.setState({
            activeItemHash: item ? item.hash : `NONE`,
        })
    }

    render() {
        const {activeItemHash} = this.state;
        return <TableOfContents
            maxDepth={this.props.maxDepth}
            activeItemHash={activeItemHash}
            {...this.props} />
    }
}


function getFlatScrollTargets(items, depth, depthLimit) {
    return items.reduce((acc, i) => {
        if (i.url) {
            let url = i.url;
            acc = [...acc, url];
        }
        if (i.items && (depth < depthLimit)) {
            acc = [...acc, ...getFlatScrollTargets(i.items, depth + 1, depthLimit)];
        }
        return acc;
    }, []);
}


const _getElementTopOffsetsById = ids => {
    const headerNav = document.getElementsByTagName('header');
    const navigationHeight = headerNav[0].offsetHeight || 0;

    return ids
        .map(hash => {
            const element = document.getElementById(hash.replace("#", ""));
            return element
                ? {
                    hash,
                    offsetTop: element.offsetTop - navigationHeight,
                }
                : null
        })
        .filter(item => item)
};


export default TableOfContentsWithScrollSpy