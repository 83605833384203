import React, {Component} from 'react'

class AnchorLink extends Component {
    constructor(props) {
        super(props);
        this.smoothScroll = this.smoothScroll.bind(this);
    }

    componentDidMount() {
        require('smoothscroll-polyfill').polyfill();
    }

    smoothScroll(e) {
        e.preventDefault();
        let offset = () => 0;
        if (typeof this.props.offset !== 'undefined') {
            if (!!(this.props.offset && this.props.offset.constructor && this.props.offset.apply)) {
                offset = this.props.offset
            } else {
                offset = () => parseInt(this.props.offset)
            }
        }
        const id = e.currentTarget.getAttribute('href').slice(1);
        const $anchor = document.getElementById(id);
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
            top: offsetTop - offset(),
            behavior: 'smooth'
        });
        if (this.props.onClick) {
            this.props.onClick(e)
        }
    }

    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            this.smoothScroll(event);
        }
    };

    render() {
        const {offset, ...rest} = this.props;
        return (
            <a {...rest}
               onClick={this.smoothScroll}
               onKeyDown={this.handleKeyPress}
               role="link"
               aria-hidden="true"
            >{this.props.children}</a>
        )
    }
}

export default AnchorLink
