import React from "react"
import {graphql} from "gatsby"
import {MDXRenderer} from "gatsby-plugin-mdx";
import TableOfContentsWithScrollSpy from "../components/table-of-contents-with-scroll-spy";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                     location
                                 }) {
    const page = data.mdx;
    const showTOC = !page.frontmatter.noTOC;
    const container = showTOC ? "container-lg" : "container-md";

    return (
        <div className={'relative'}>
            <div className={"container mx-auto mt-8 pb-6 mb-4 " + container}>
                <div className="mx-4 md:mx-8 border-b border-gray-300">
                    <h1 className={'text-3xl text-gray-800 md:text-4xl font-bold'}>{page.frontmatter.title}</h1>
                    <p className={'text-gray-500 text-xl'}>{page.frontmatter.description}</p>
                </div>
            </div>

            <div className={'container flex align-start mb-12 mx-auto ' + container}>
                {showTOC ?
                    <div className="order-2 hidden lg:block pr-8" style={{minWidth: '12rem'}}>
                        <div className="sticky mt-8" style={{'top': '6rem'}}>
                            <TableOfContentsWithScrollSpy
                                items={page.tableOfContents.items}
                                title={page.frontmatter.title}
                                maxDepth={2}
                                location={location}/>
                        </div>
                    </div>
                    : ""
                }
                <div className={'markdown w-full mx-4 md:mx-8'}>
                    <MDXRenderer>{page.body}</MDXRenderer>
                </div>
            </div>
        </div>

    )
}

export const pageQuery = graphql`
 query DocLayoutPageQuery($slug: String) {
     mdx(frontmatter: {slug: {eq: $slug}}) {
      id
      body
      headings {
        depth
        value
      }
      tableOfContents
      frontmatter {
        slug
        title
        description
        excludeFromIndex
        noTOC
        sidebar
        tableOfContentsDepth
      }
    }
    site: site {
        siteMetadata {
            title
            siteUrl
        }
    }
  }
`;
